'use strict';

angular.module('kljDigitalLibraryApp')
    .service('CheckoutService', ["$q", "common", "Auth", "appConfig",
        function($q, common, Auth, appConfig) {

            this.getPaymentDetails = function(subscription_id, couponCode, isGuest) {
                var deferred = $q.defer();

                common.callApi('POST',
                        '/api/order/getPayUMoneyShaKey',
                        appConfig.EMPTY_STRING,
                        appConfig.CONTENT_TYPE, {
                            subscription: subscription_id,
                            coupon: couponCode,
                            guest: isGuest
                        }
                    )
                    .then(function(response) {
                        deferred.resolve(response.data);
                    })
                    .catch(function(error) {
                        deferred.reject(error.data);
                    });

                return deferred.promise;
            }



        }
    ]);